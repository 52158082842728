@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@700;400&display=swap');

body,
html {
  font-family: 'Mulish', sans-serif !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ecf0f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Text-LineProgress {
  text-align: 'left';
  z-index: 100;
}

.Text-Temperatur {
  font-size: 50px;
  /* transform: scale(1, 1.2); */
  margin: auto 0;
  /* line-height: 200%; */
}

.Text-Counter {
  font-size: 160px;
  /* letter-spacing: 10px; */
  /* transform: scale(1, 1.2); */
}

@media (width <=1300px) {
  .Text-Counter {
    font-size: 100px;
  }
}

.Text-Sensor {
  font-size: 70px;
  margin: auto 0;
}

.Text-Absolute {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Text-Absolute-Sub {
  z-index: 1;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Button-Absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 1;
  cursor: pointer;
  opacity: 100;
  padding: 10px;
}

.Label-Absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 100;
  padding: 5px;
}

.Header-Container {
  display: flex;
  justify-content: space-between;
  background-color: #a5d4e7;
}

@media (width <=900px) {
  .Header-Container {
    flex-direction: column;
  }
}